
export const iconsNames = [
    'person',
    'people',
    'groups',
    'people_alt',
    'person_outline',
    'people_outline',
    'assignment_ind',
    'admin_panel_settings',

    'home',
    'maps_home_work',

    'face',
    'mood',
    'sentiment_very_dissatisfied',
    'sentiment_neutral',

    'info',
    'privacy_tip',
    'help_outline',
    'help',
    'warning_amber',
    'warning',
    'error',
    'error_outline',
    'report',
    'report_gmailerrorred',
    'dangerous',
    'new_releases',
    'verified',

    'cancel',
    'highlight_off',
    'check_circle',
    'offline_pin',
    'task_alt',
    'check_circle_outline',
    'flaky',

    'event',
    'date_range',
    'event_available',
    'event_note',
    'event_busy',
    'edit_calendar',
    'schedule',
    'watch_later',
    'history',
    'update',
    'timer',
    'more_time',
    'pending_actions',
    'alarm',
    'alarm_on',
    'snooze',
    'hourglass_empty',
    'hourglass_bottom',
    'hourglass_top',
    'hourglass_full',
    'delete',
    'auto_delete',
    'delete_forever',

    'done',
    'done_all',
    'download_done',
    'clear',
    'remove',
    'add',

    'lock',
    'lock_open',

    'radio_button_unchecked',
    'trip_origin',
    'radio_button_checked',
    'adjust',
    'circle',

    'notifications',
    'notifications_active',
    'notifications_none',
    'notification_important',
    'notifications_paused',
    'notifications_off',
    'circle_notifications',
    'add_circle_outline',
    'remove_circle_outline',

    'local_offer',
    'loyalty',

    'bookmark',
    'bookmark_border',
    'bookmarks',
    'class',
    'comment_bank',

    'thumb_up_alt',
    'thumb_up_off_alt',
    'thumb_down_alt',
    'thumb_down_off_alt',
    'thumbs_up_down',

    'looks',
    'looks_one',
    'looks_two',
    'looks_3',
    'looks_4',
    'looks_5',
    'looks_6',
    'bug_report',

    'phone_iphone',
    'smartphone',
    'phone_android',
    'devices_other',
    'app_settings_alt',
    'app_blocking',
    'phonelink_lock',
    'mobile_friendly',
    'device_unknown',
    'charging_station',

    'rule',
    'checklist_rtl',
    'rule_folder',
    'fact_check',
    'summarize',
    'ballot',
    'speaker_notes',
    'format_list_numbered_rtl',

    'replay',
    'sync',
    'cached',
    'published_with_changes',

    'construction',
    'handyman',
    'build_circle',
    'settings_applications',
    'miscellaneous_services',
    'video_settings',
    'language',
    'visibility',

    'star',
    'star_half',
    'star_border',
    'stars',
    'folder_special',
    'military_tech',

    'password',
    'pin',
    'vpn_key',

    'shopping_cart',
    'shopping_basket',
    'request_quote',
    'euro',
    'monetization_on',
    'price_change',

    'attach_file',
    'extension',
    'group_work',
    'support',
    'calculate',
    'stream',
    'g_translate',
    'offline_bolt',
    'east',
    'west',
    'south',
    'north',
    'north_east',
    'south_east',
    'undo',
    'redo',
    'file_download',
    'file_upload',
    'cloud_upload',
    'cloud_download',
    'public',
    'travel_explore',
    'coronavirus',
    'whatshot',
    'fireplace',
    'recycling',
    'workspace_premium',
    'auto_stories',
    'filter_vintage',

    'flag',
    'outlined_flag',
    'tour',
    'sports_score',
    'emoji_flags',
    'favorite',
    'favorite_border'

];